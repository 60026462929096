<template>
  <div class="block-hero-consultations">
    <div class="block-hero-consultations__image-container">
      <AtomImage
        v-if="product?.hero_image"
        behaviour="contain"
        :data="product.hero_image"
        :is-strapi="true"
        class="block-hero-consultations__image"
      />
      <div class="block-hero-consultations__image-flags-container">
        <div class="block-hero-consultations__image-flags-event-controls">
          <ClientOnly>
            <ShopProductControls
              v-if="user && bookMarksInitialized"
              :has-bookmark-icon="true"
              :has-calendar-icon="false"
              :has-loved-icon="false"
              :has-helped-icon="false"
              :has-cart-icon="false"
            />
          </ClientOnly>
        </div>
      </div>
    </div>
    <div class="block-hero-consultations__content">
      <div class="block-hero-consultations__content-name">
        {{ product?.label }}
      </div>

      <AtomHeading
        v-if="product?.name"
        class="block-hero-consultations__content-label"
        :text="product?.name"
        tag="h1"
        appereance="h1"
      />
      <AtomRichtext
        v-if="product?.hero_lead"
        class="block-hero-consultations__content-text"
        :string="product?.hero_lead"
      />
      <div class="block-hero-consultations__content-detail-wrapper">
        <div class="block-hero-consultations__content-author">
          <AtomImage
            v-if="product?.speaker?.image?.url"
            :src="product.speaker.image.url"
            class="block-hero-consultations__content-author-img"
          />
          <span v-if="product?.speaker?.name">
            {{ useTranslation('general', 'with') }} {{ product.speaker.name }}
          </span>
        </div>
        <div v-if="product?.event_type?.name" class="block-hero-consultations__content-detail">
          <AppIcon
            class="block-hero-consultations__content-detail-icon"
            icon="clock"
            :is-raw="true"
          />
          {{ product.event_type.name }}
        </div>
      </div>
      <ShopProductCardPrice
        class="block-hero-consultations__content-price"
        :is-hero="true"
      />
      <div class="block-hero-consultations__book-event">
        <AtomButton
          :text="useTranslation('general', 'book_now')"
          @click="scrollToAnchor(data.scroll_anchor)"
          @keydown.enter="scrollToAnchor(data.scroll_anchor)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

/*
  Setup
*/
const { dayjs } = useDayJs();
const user = useStrapiUser();
const storyStore = useStoryStore();

/*
Provide data to nested components
*/
const product = computed(() => storyStore.getConnectedStrapiData);

provide('item', product.value);
provide('product', product.value);

/*
  Get available dates of event
*/
const dates = computed(() => storyStore.getConnectedStrapiValue('dates')?.map((date) => {
    const formattedStartDate = dayjs(date.date);
    const formattedEndDate = dayjs(date.end_date);

    return {
        _uid: Math.random() + date.date,
        value: formattedStartDate,
        endValue: formattedEndDate,
    };
}));

/* Sort dates */
dates.value?.sort((a, b) => {
    const dateA = dayjs(a.value);
    const dateB = dayjs(b.value);

    if (dateA.isBefore(dateB)) {
        return -1;
    }
    if (dateA.isAfter(dateB)) {
        return 1;
    }
    return 0;
});

/*
  Provide for interactive order items
*/
provide('is-editable', true);

/*
  Get emmited data from atom image switch
*/
const bookmarkStore = useBookmarkStore();
const bookMarksInitialized = computed(() => bookmarkStore.getInitialized);

/*
  Scroll to anchor
*/
const { gsapScrollTo } = useGsap();
const scrollToAnchor = (anchor) => {
    gsapScrollTo(window, {
        duration: props.data.scroll_duration,
        ease: 'Circ.easeInOut',
        scrollTo: { y: `#${anchor}`, offsetY: props.data.scroll_offset },
    });
};
</script>

<style lang="scss">
.block-hero-consultations {
    @include fluid('margin-bottom', 20px, 40px);

    display: flex;
    overflow: hidden;

    background-color: $C_GREEN_BRIGHT;
    border-bottom-right-radius: 280px;
    isolation: isolate;

    @include mobile {
        height: 100%;
        min-height: unset;
        flex-direction: column;
        border-bottom-right-radius: 50px;
    }
}

.block-hero-consultations__brand-logo {
    @include grid-columns(1);
    @include fluid('width', 103px, 227px);
    @include fluid('bottom', 20px, 80px);

    position: absolute;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    aspect-ratio: 1.31;
    background-color: $C_WHITE;

    img {
        position: absolute;
        top: 0;
        left: 20%;
        width: 60%;
        height: 100%;
        object-fit: contain;
        object-position: center center;
    }
}

.block-hero-consultations__image-container {
    @include grid-columns(1, 8);

    position: relative;
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: flex-end;

    @include mobile {
        width: 100%;
        height: 520px;
    }

    .block-hero-consultations__image {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;

        img {
            object-fit: cover;
        }
    }

    .block-hero-consultations__image-flags-container {
        @include fluid('margin-bottom', 20px, 60px);
        @include z-index('main');

        display: flex;
        width: 100%;
        align-items: flex-end;
        justify-content: space-between;
        padding-left: var(--page-padding);

        @include mobile{
            flex-direction: column-reverse;
            align-items: flex-start;
        }
        justify-self: flex-end
    }

    .block-hero-consultations__image-flag {
        padding: 15px 20px;
        border-radius: 6px;
        background-color: $C_WHITE;
    }

    @include tablet {
        @include grid-columns(1, 6);
    }
}

.block-hero-consultations__content {
    @include grid-columns(9, 10);

    position: relative;
    display: flex;
    overflow: hidden;
    width: 50%;
    height: 100%;
    min-height: 690px;
    flex-direction: column;
    padding-top: 209px;
    padding-right: 185px;
    padding-left: 87px;
    margin-right: calc(0px - var(--grid-offset));
    background-color: $C_GREEN__HERO;
    border-top-left-radius: 120px;

    .block-hero-consultations__content-flags {
        @include fluid('margin-bottom', 21px, 30px);

        display: flex;
        flex-direction: row;

        .block-hero-consultations__content-discount {
            @include typo-font('regular');
            @include typo-size('nav');

            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 20px;
            border-radius: 6px;
            margin-right: 10px;
            background-color: $C_RED;
            color: $C_WHITE;
        }
    }

    .block-hero-consultations__content-name {
        @include typo-font('regular');
        @include typo-size('pretitel');

        color: $C_GREEN_DARK;
    }

    .block-hero-consultations__content-label {
        @include fluid('margin-bottom', 10px, 15px);
        @include z-index('main');
    }

    .block-hero-consultations__content-text {
        @include z-index('main');
        @include fluid('margin-bottom', 10px, 20px);
    }

    .block-hero-consultations__content-price {
        @include fluid('margin-bottom', 13px, 20px);
        @include z-index('main');

        display: flex;
        flex-direction: column-reverse;
        color: $C_GREEN_DARK;
    }

    .block-hero-consultations__book-event {
        @include fluid('margin-bottom', 20px, 60px);

        display: flex;
        align-items: center;

        span {
            @include typo-font('regular');
            @include typo-size('pretitel');

            color: $C_GREEN_DARK;
        }
    }

    .block-hero-consultations__content-attributes {
        z-index: 1;
        bottom: 50px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 60px;

        .block-hero-consultations__content-attribute {
            @include typo-font('regular');
            @include typo-size('nav');

            display: flex;
            height: 54px;
            align-items: center;
            padding: 0 20px 0 20px;
            border-radius: 6px;
            margin-right: 10px;
            background-color: $C_WHITE;
            color: $C_GREEN_DARK;
        }

        .atom-image {
            margin-right: 10px;
        }

        @include mobile {
            position: relative;
            bottom: 0;
            margin-bottom: 48px;
        }
    }

    @include tablet {
        @include grid-columns(7, 6);
    }

    @include mobile {
        @include grid-columns(2, 4);
    }

    &:before {
        position: absolute;
        right: -140px;
        bottom: -230px;
        width: 563px;
        height: 618px;
        background-color: $C_PRIMARY;
        content: '';
        filter: blur(165px);
        opacity: 0.75;
        transform: matrix(-0.87, -0.49, 0.5, -0.87, 0, 0);

        @include mobile {
            display: none;
        }
    }

    &:after {
        position: absolute;
        right: 244px;
        bottom: 72px;
        width: 143px;
        height: 143px;
        background-color: $C_GREEN_DARK;
        content: '';
        filter: blur(114.5px);
        transform: matrix(-0.87, -0.49, 0.5, -0.87, 0, 0);

        @include mobile {
            display: none;
        }
    }

    @include tablet {
        padding-right: 20px;
        padding-left: 30px;
    }

    @include mobile {
        width: 100%;
        padding-top: 40px;
        border-top-left-radius: 50px;
    }
}

.block-hero-consultations__content-author {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 25px;

    .block-hero-consultations__content-author-img {
        margin-right: 6px;

        img {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
}

.block-hero-consultations__content-detail-wrapper {
    @include typo-font('regular');
    @include typo-size('caption');
    @include z-index('header');

    margin-bottom: 28px;
    color: $C_GREEN_DARK;
}

.block-hero-consultations__content-detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }

    .block-hero-consultations__content-detail-icon {
        width: 24px;
        height: 24px;
        margin-right: 16px;
    }
}
</style>
